import Comment from 'components/comment/Comment'
import React from 'react'
import { SocialComment } from 'types'

export default function CommentSection({
  comments,
  updateScore,
  loading,
}: {
  comments: SocialComment[]
  updateScore: (uuid: string, amount: number) => Promise<void>
  loading: boolean
}) {
  const sortedComments = comments.sort((a, b) => Date.parse(b.timestamp) - Date.parse(a.timestamp))
  return (
    <div className="">
      {loading && <Comment key="pendingComment" updateScore={updateScore} />}
      {comments.map((comment) => (
        <Comment key={comment.uuid} comment={comment} updateScore={updateScore} />
      ))}
    </div>
  )
}
