import Post from 'components/post/Post'
import React, { useEffect, useState } from 'react'
import { SocialPost } from 'types'

export default function PostList({
  posts,
  loading,
  updateScore,
}: {
  posts: SocialPost[]
  loading: boolean
  updateScore: (uuid: string, amount: number) => void
}) {
  const sortedPosts = posts.sort((a, b) => Date.parse(b.timestamp) - Date.parse(a.timestamp))
  return (
    <div>
      {loading && (
        <Post
          key="placeholder"
          upvoteFunction={() => updateScore('', 0)}
          downvoteFunction={() => updateScore('', 0)}
        ></Post>
      )}
      {sortedPosts.map((post) => (
        <Post
          key={post.uuid}
          post={post}
          upvoteFunction={() => updateScore(post.uuid, 1)}
          downvoteFunction={() => updateScore(post.uuid, -1)}
        />
      ))}
    </div>
  )
}
