import React, { ChangeEvent, Component } from 'react'
import { Content, PostSubmission } from 'types'

import './PostForm.scss'

enum FormInputType {
  Username,
  Title,
  ImageLink,
  Link,
  TextContent,
}

type PostFormState = {
  username: string
  title: string
  imageLink: string
  link: string
  textContent: string
  waiting: boolean
}

type PostFormProps = {
  onSuccessfulSubmit: () => Promise<void>
}

export default class PostForm extends Component<PostFormProps, PostFormState> {
  constructor(props: PostFormProps) {
    super(props)
    this.state = {
      username: '',
      title: '',
      imageLink: '',
      link: '',
      textContent: '',
      waiting: false,
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange(property: FormInputType, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    switch (property) {
      case FormInputType.Username: {
        this.setState({
          ...this.state,
          username: event.target.value,
        })
        break
      }
      case FormInputType.Title: {
        this.setState({
          ...this.state,
          title: event.target.value,
        })
        break
      }
      case FormInputType.ImageLink: {
        this.setState({
          ...this.state,
          imageLink: event.target.value,
        })
        break
      }
      case FormInputType.Link: {
        this.setState({
          ...this.state,
          link: event.target.value,
        })
        break
      }
      case FormInputType.TextContent: {
        this.setState({
          ...this.state,
          textContent: event.target.value,
        })
        break
      }
    }
  }

  async handleSubmit() {
    if (this.state.username === '' || this.state.title === '' || this.state.textContent === '') {
      return
    }

    let content: Content[] = []
    if (this.state.imageLink !== '') {
      content.push({ type: 'Image', content: this.state.imageLink })
    }
    if (this.state.link !== '') {
      content.push({ type: 'Link', content: this.state.link })
    }
    if (this.state.textContent !== '') {
      content.push({ type: 'Text', content: this.state.textContent })
    }
    const body: PostSubmission = {
      username: this.state.username,
      title: this.state.title,
      content: content,
    }
    this.setState({
      ...this.state,
      waiting: true,
    })
    let response = await fetch(`${process.env.REACT_APP_API_URL}/post`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(body),
    })
    if (!response.ok || (response.status >= 400 && response.status < 600)) {
      console.log(response)
      this.setState({
        ...this.state,
        waiting: false,
      })
      Promise.reject(response)
    } else {
      this.setState({
        ...this.state,
        title: '',
        imageLink: '',
        link: '',
        textContent: '',
        waiting: false,
      })
      this.props.onSuccessfulSubmit()
    }
  }

  render() {
    return (
      <div>
        <div className="input-group mb-3">
          <span className="input-group-text" id="basic-addon1">
            @
          </span>
          <input
            type="email"
            className="form-control"
            id="usernameInput"
            placeholder="username"
            aria-describedby="basic-addon1"
            value={this.state.username}
            onChange={(event) => this.handleChange(FormInputType.Username, event)}
          ></input>
        </div>
        <div className="form-floating mb-3">
          <input
            type="url"
            className="form-control"
            id="titleInput"
            placeholder="potato"
            value={this.state.title}
            onChange={(event) => this.handleChange(FormInputType.Title, event)}
          ></input>
          <label htmlFor="titleInput">post title</label>
        </div>
        <div className="form-floating mb-3">
          <input
            type="url"
            className="form-control"
            id="imageInput"
            placeholder="potato"
            value={this.state.imageLink}
            onChange={(event) => this.handleChange(FormInputType.ImageLink, event)}
          ></input>
          <label htmlFor="imageInput">image link (optional)</label>
        </div>
        <div className="form-floating mb-3">
          <input
            type="url"
            className="form-control"
            id="linkInput"
            placeholder="potato"
            value={this.state.link}
            onChange={(event) => this.handleChange(FormInputType.Link, event)}
          ></input>
          <label htmlFor="linkInput">link (optional)</label>
        </div>
        <div className="form-floating mb-3">
          <textarea
            className="form-control"
            placeholder="potato"
            id="postContent"
            value={this.state.textContent}
            onChange={(event) => this.handleChange(FormInputType.TextContent, event)}
          ></textarea>
          <label htmlFor="postContent">post text</label>
        </div>
        <div className="mb-3">
          {this.state.waiting ? (
            <button type="submit" className="btn btn-fill" onClick={this.handleSubmit} disabled>
              submitting...
            </button>
          ) : (
            <button type="submit" className="btn btn-fill" onClick={this.handleSubmit}>
              submit post
            </button>
          )}
        </div>
      </div>
    )
  }
}
