import { stat } from 'fs'
import React, { useState, ChangeEvent } from 'react'
import { CommentSubmission, Content } from 'types'

type CommentPostBarState = {
  username: string
  comment: string
  waiting: boolean
}

export default function CommentPostBar({
  parentUUID,
  onSuccessfulSubmit,
}: {
  parentUUID: string
  onSuccessfulSubmit: () => Promise<void>
}) {
  let [state, setState] = useState<CommentPostBarState>({ username: '', comment: '', waiting: false })

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    switch (event.target.name) {
      case 'username': {
        setState({
          ...state,
          username: event.target.value,
        })
        break
      }
      case 'comment': {
        setState({
          ...state,
          comment: event.target.value,
        })
        break
      }
    }
  }

  const handleSubmit = async () => {
    if (state.comment === '' || state.username === '') {
      return
    }

    let content: Content[] = [{ type: 'Text', content: state.comment }]

    const body: CommentSubmission = {
      username: state.username,
      parentUUID: parentUUID,
      content: content,
    }
    setState({
      ...state,
      waiting: true,
    })

    let response = await fetch(`${process.env.REACT_APP_API_URL}/comment`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
      body: JSON.stringify(body),
    })

    if (!response.ok || (response.status >= 400 && response.status < 600)) {
      console.log(response)
      setState({
        ...state,
        waiting: false,
      })
      // Promise.reject(response)
    } else {
      setState({
        ...state,
        comment: '',
        waiting: false,
      })
      onSuccessfulSubmit()
    }
  }

  return (
    <div className="row">
      <div className="col">
        <div className="input-group">
          <span className="input-group-text" id="addon-thing">
            @
          </span>
          <input
            onChange={handleChange}
            value={state.username}
            type="email"
            name="username"
            className="form-control"
            placeholder="username"
            aria-describedby="addon-thing"
          ></input>
          <input
            onChange={handleChange}
            value={state.comment}
            type="text"
            name="comment"
            className="form-control"
            id="floatingComment"
            placeholder="comment"
          ></input>
          {!state.waiting ? (
            <button className="btn btn-fill" onClick={handleSubmit}>
              send
            </button>
          ) : (
            <button className="btn btn-fill" disabled>
              sending...
            </button>
          )}
        </div>
      </div>
    </div>
  )
}
