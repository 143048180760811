import React from 'react'
import { ArrowDownCircleFill, ArrowUpCircleFill } from 'react-bootstrap-icons'

import './VoteButtons.scss'

export default function VoteButtons({
  score,
  upvoteFunction,
  downvoteFunction,
  className,
}: {
  score?: number
  upvoteFunction: () => void
  downvoteFunction: () => void
  className?: string
}) {
  if (score != null) {
    return (
      <div className={(className ?? '') + ' btn-group bg-light border rounded-pill'}>
        <button
          type="button"
          className="btn btn-vote d-flex justify-content-center align-items-center"
          onClick={upvoteFunction}
        >
          <ArrowUpCircleFill />
        </button>
        <button type="button" className="btn btn-score d-flex justify-content-center align-items-center" disabled>
          {score}
        </button>
        <button
          type="button"
          className="btn btn-vote d-flex justify-content-center align-items-center"
          onClick={downvoteFunction}
        >
          <ArrowDownCircleFill />
        </button>
      </div>
    )
  } else {
    return (
      <div className={(className ?? '') + ' btn-group bg-light border rounded-pill'}>
        <button type="button" className="btn btn-vote d-flex justify-content-center align-items-center" disabled>
          <ArrowUpCircleFill />
        </button>
        <button
          type="button"
          className="btn btn-score d-flex justify-content-center align-items-center placeholder-glow"
          disabled
        >
          <span className="placeholder col"></span>
        </button>
        <button type="button" className="btn btn-vote d-flex justify-content-center align-items-center" disabled>
          <ArrowDownCircleFill />
        </button>
      </div>
    )
  }
}
