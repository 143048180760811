import React from 'react'
import Home from 'pages/home/Home'

import './App.scss'

function App() {
  return <Home />
}

export default App
