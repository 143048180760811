import React from 'react'

import { PeopleFill } from 'react-bootstrap-icons'

import './Navbar.scss'

export default function Navbar() {
  return (
    <header className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 mb-4 border-bottom social-navbar">
      <a href="/" className="d-flex align-items-center col-md-3 mb-2 mb-md-0 text-decoration-none nav-brand">
        <PeopleFill className="navbar-logo" /> SocialApp
      </a>

      <div className="col-md-3 text-end">
        <button type="button" className="btn btn-outline me-2">
          Login
        </button>
        <button type="button" className="btn btn-fill ">
          Sign-up
        </button>
      </div>
    </header>
  )
}
