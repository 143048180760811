import { LinkPreview } from '@dhaiwat10/react-link-preview'

import { formatRelative } from 'date-fns'
import { CommmentVoteSubmission, SocialComment, SocialPost } from 'types'

import './Post.scss'
import { ArrowDownCircleFill, ArrowUpCircleFill } from 'react-bootstrap-icons'
import VoteButtons from 'components/vote-buttons/VoteButtons'
import CommentPostBar from 'components/comment-post-bar/CommentPostBar'
import CommentSection from 'components/comment-section/CommentSection'
import { useEffect, useState } from 'react'

type PostState = {
  comments: SocialComment[]
  loading: boolean
}

export default function Post({
  post,
  upvoteFunction,
  downvoteFunction,
}: {
  post?: SocialPost
  upvoteFunction: () => void
  downvoteFunction: () => void
}) {
  const [state, setState] = useState<PostState>({
    comments: [],
    loading: false,
  })

  async function fetchComments(): Promise<void> {
    if (post != null) {
      setState({
        ...state,
        loading: true,
      })
      const response = await fetch(`${process.env.REACT_APP_API_URL}/comment/${post?.uuid}`, {
        mode: 'cors',
      })
      if (!response.ok || (response.status >= 400 && response.status < 600)) {
        setState({
          ...state,
          loading: false,
        })
        Promise.reject(response)
      } else {
        setState({
          ...state,
          comments: await response.json(),
          loading: false,
        })
      }
    }
  }

  const updateScore = async (uuid: string, amount: number): Promise<void> => {
    if (Math.abs(amount) !== 1) {
      return
    }

    const body: CommmentVoteSubmission = {
      uuid: uuid,
      upvote: amount > 0,
    }
    let response = await fetch(`${process.env.REACT_APP_API_URL}/voteComment`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
      body: JSON.stringify(body),
    })

    if (!response.ok || (response.status >= 400 && response.status < 600)) {
      return
    } else {
      const json = await response.json()
      const newScore = json.newScore
      const newUUID = json.uuid
      setState({
        ...state,
        comments: state.comments.map((comment) => {
          if (comment.uuid === newUUID) {
            return {
              ...comment,
              score: newScore,
            }
          } else {
            return comment
          }
        }),
      })
    }
  }

  useEffect(() => {
    fetchComments()
  }, [])

  if (post != null) {
    const image = post.content.find((content) => content.type === 'Image')
    const link = post.content.find((content) => content.type === 'Link')
    const text = post.content.find((content) => content.type === 'Text')
    return (
      <div className="card post-card rounded-5">
        {image != null && <img src={image?.content} className="card-img-top" alt="post content" />}
        <div className="card-body">
          <h2 className="card-title">{post.title}</h2>
          <h6 className="card-subtitle mb-2 text-muted">posted by @{post.username}</h6>
          <h6 className="card-subtitle mb-2 text-muted">{formatRelative(Date.parse(post.timestamp), new Date())}</h6>
          {text != null && <p className="card-text">{text?.content}</p>}
          {link != null && <LinkPreview url={link?.content ?? ''} />}
          <div className="row justify-content-center">
            <div className="col d-flex justify-content-center">
              <VoteButtons score={post.score} upvoteFunction={upvoteFunction} downvoteFunction={downvoteFunction} />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <CommentPostBar parentUUID={post.uuid} onSuccessfulSubmit={fetchComments} />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <CommentSection comments={state.comments} updateScore={updateScore} loading={state.loading} />
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="card post-card rounded-5">
        {/* <img className="card-img-top" alt="placeholder content" /> */}
        <div className="card-body">
          <h2 className="card-title placeholder-glow">
            <span className="placeholder col-6"></span>
          </h2>
          <h6 className="card-subtitle mb-2 text-muted placeholder-glow">
            <span className="placeholder col-3"></span>
          </h6>
          <h6 className="card-subtitle mb-2 text-muted placeholder-glow">
            <span className="placeholder col-5"></span>
          </h6>
          <p className="card-text placeholder-glow">
            <span className="placeholder col-7"></span>
            <span className="placeholder col-4"></span>
            <span className="placeholder col-4"></span>
            <span className="placeholder col-6"></span>
            <span className="placeholder col-8"></span>
          </p>
          <div className="row justify-content-center">
            <div className="col-4">
              <VoteButtons upvoteFunction={upvoteFunction} downvoteFunction={downvoteFunction} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
