import VoteButtons from 'components/vote-buttons/VoteButtons'
import { formatRelative } from 'date-fns'
import React from 'react'
import { SocialComment } from 'types'

import './Comment.scss'

export default function Comment({
  comment,
  updateScore,
}: {
  comment?: SocialComment
  updateScore: (uuid: string, amount: number) => Promise<void>
}) {
  if (comment) {
    const textComment = comment.content.find((content) => content.type === 'Text')
    return (
      <div className="row comment-row" key={comment.uuid}>
        <div className="col-8">
          <div>
            <strong>@{comment.username}</strong> <span className="mb-1">{textComment?.content ?? ''}</span>
          </div>
          <div>
            <small>{formatRelative(Date.parse(comment.timestamp), new Date())}</small>
          </div>
        </div>
        <div className="col d-flex justify-content-end align-items-center">
          <VoteButtons
            className="comment-votes"
            score={comment.score}
            upvoteFunction={() => updateScore(comment.uuid, 1)}
            downvoteFunction={() => updateScore(comment.uuid, -1)}
          />
        </div>
      </div>
    )
  } else {
    return (
      <div className="row comment-row">
        <div className="col-8">
          <span>
            <strong className="placeholder-glow">
              <span className="placeholder col-6"></span>
            </strong>
            <span className="mb-1 placeholder-glow">
              <span className="placeholder col-3"></span>
              <span className="placeholder col-5"></span>
              <span className="placeholder col-6"></span>
            </span>
          </span>
          <span className="placeholder-glow">
            <small>
              <span className="placeholder col-6"></span>
            </small>
          </span>
        </div>
        <div className="col d-flex justify-content-end align-items-center">
          <VoteButtons className="comment-votes" upvoteFunction={() => {}} downvoteFunction={() => {}} />
        </div>
      </div>
    )
  }
}
