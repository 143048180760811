import React, { useEffect, useState } from 'react'

import Navbar from 'components/navbar/Navbar'

import PostForm from 'components/post-form/PostForm'
import PostList from 'components/post-list/PostList'
import { PostVoteSubmission, SocialPost } from 'types'

type HomeState = {
  posts: SocialPost[]
  loading: boolean
}

export default function Home() {
  let [state, setState] = useState<HomeState>({
    posts: [],
    loading: true,
  })

  async function fetchPosts(): Promise<void> {
    setState({
      ...state,
      loading: true,
    })
    const response = await fetch(`${process.env.REACT_APP_API_URL}/post`, {
      mode: 'cors',
    })
    if (!response.ok || (response.status >= 400 && response.status < 600)) {
      setState({
        ...state,
        loading: false,
      })
      Promise.reject(response)
    } else {
      setState({
        ...state,
        posts: await response.json(),
        loading: false,
      })
    }
  }

  async function updateScore(uuid: string, amount: number): Promise<void> {
    if (Math.abs(amount) !== 1) {
      return
    }
    // setState({
    //   ...state,
    //   posts: state.posts.map((post) => {
    //     console.log(post)
    //     if (post.uuid === uuid) {
    //       return {
    //         ...post,
    //         score: post.score + amount,
    //       }
    //     } else {
    //       return post
    //     }
    //   }),
    // })

    const body: PostVoteSubmission = {
      uuid: uuid,
      upvote: amount > 0,
    }
    let response = await fetch(`${process.env.REACT_APP_API_URL}/votePost`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
      body: JSON.stringify(body),
    })

    if (!response.ok || (response.status >= 400 && response.status < 600)) {
      // setState({
      //   ...state,
      //   posts: state.posts.map((post) => {
      //     if (post.uuid === uuid) {
      //       return {
      //         ...post,
      //         score: post.score - amount,
      //       }
      //     } else {
      //       return post
      //     }
      //   }),
      // })
      return
    } else {
      const json = await response.json()
      const newScore = json.newScore
      const newUUID = json.uuid
      setState({
        ...state,
        posts: state.posts.map((post) => {
          console.log(post)
          if (post.uuid === newUUID) {
            return {
              ...post,
              score: newScore,
            }
          } else {
            return post
          }
        }),
      })
    }
  }

  useEffect(() => {
    fetchPosts()
  }, [])

  return (
    <div className="container">
      <Navbar />
      <div className="row">
        <div className="col-lg">
          <PostForm onSuccessfulSubmit={fetchPosts} />
        </div>
        <div className="col-lg-8 ">
          <PostList posts={state.posts} loading={state.loading} updateScore={updateScore} />
        </div>
      </div>
    </div>
  )
}
